

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

ol{
    padding: 0 0 0 15px;
}

ol li{
    margin-bottom: 10px;
}
