:root {
    --color-blue-pb: #3bbff8;
    --max-width: 1200px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-size: 10px;
}

.pb-page-wrapper {
    max-width: var(--max-width);
    margin: 0 auto;
}

.pb-popup-wrapper {
    position: fixed;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
}

.pb-popup-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 10px 10px;
    width: 90%;
    max-width: 400px;
    margin: max(150px, 15%) auto 0;
    min-height: 100px;
}

.pb-button {
    display: block;
    margin: 10px auto;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    background-color: #2bafe8;
    border: none;
    min-width: 200px;
    cursor: pointer;
}

.pb-button:hover {
    background-color: var(--color-blue-pb);
}

.pb-slider-page {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    height: 100vh;
    width: 100vw;
    transition: all .375s ease;
    transform: translate3d(100%, 0, 0);
}

.pb-slider-page.active {
    transform: translate3d(0, 0, 0);
}

.pb-slider-page-container {
    padding: 20px;
    text-align: left;
}

.pb-slider-page-container h2 {
    font-size: 3rem;
    color: #444;
}

.pb-slider-page-container p {
    font-size: 1.4rem;
    margin: 10px 0;
    font-weight: 450;
}

.pb-slider-page-header {
    background-color: var(--color-blue-pb);
    padding: 30px 20px;
    border-radius: 0 0 10px 10px;
}

.pb-slider-page-header img {
    max-width: 200px;
}

.pb-primary-button {
    margin: 20px 0;
    padding: 10px 50px 10px 20px;
    border-radius: 25px;
    color: #fff;
    font-weight: 500;
    letter-spacing: .8px;
    background-color: #2bafe8;
    border: none;
    font-size: 1.7rem;
    cursor: pointer;
    position: relative;
}

.pb-primary-button::after {
    content: "";
    position: absolute;
    display: block;
    right: 10px;
    top: 7px;
    width: 25px;
    height: 25px;
    background-image: url('../../images/arrow.png');
    background-size: contain;
    transform: rotateZ(180deg);
}

.pb-primary-button:hover {
    background-color: var(--color-blue-pb);
}

.pb-footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
}

.pb-decor-image {
    max-width: 100%;
}

.pb-loading-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 10px 30px;
    width: 90%;
    max-width: 400px;
    margin: max(150px, 15%) auto 0;
    min-height: 100px;
}

.pb-loading-container img {
    max-width: 100px;
}

.pb-loading-container h2 {
    font-size: 3rem;
}

.pb-loading-container p {
    font-size: 1.5rem;
}

@media screen and (min-width: 768px) {
    .pb-slider-page-container {
        margin: 20px auto;
        max-width: 600px;
    }
}